export const links = {
	// bearerUrl: 'https://moneytrust.test/api/v1',
	// galleryUrl: 'http://moneytrust.test/storage',
	bearerUrl: 'https://backend.pubsec.moneytrustmfb.com/api/v1',
	galleryUrl: 'https://backend.pubsec.moneytrustmfb.com/storage',
	pages: {
		login: 'auth/login',
		register: 'auth/register',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		profile: 'agents/profile',
		profilePhoto: 'agents/profile-photo',
		updateProfile: 'agents/update-profile',
		offers: 'agents/offers',
		processOffer: 'agents/offers/action',
		logout: 'logout',
		loans: 'agents/loans',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		verifyTransaction: 'agents/tranx/payment_confirmation',
		pools: 'agents/pools',
		bookInvestment: 'agents/invest_now',
		investments: 'agents/investments',
		savings: 'agents/savings',
		verifyLoan: 'agents/loan-verification',
		topUp: 'agents/loan-topup',
		dashboard: 'agents/dashboard',
		verifyBvn: 'verify_bvn',
		investmentCert: 'agents/investments/preview',
		investmentLetter: 'agents/investments/preview/letter',
		checkCustomer: 'agents/check_customer',
		bookLoanViaCustomer: 'agents/create_loan_from_customer',
	},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
